import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import useDimension from "../../hooks/use-dimension";
import Constants from "../../constants";
import * as Theme from "../../Theme";
import ApiBlog from "../../ApiBlog";
import Breadcrumb from "../../Components/Breadcrumb";
import NextArticles from "./NextArticles";
import time from "../../Utils/TimeFormat";
import ShareLinks from "./ShareLinks";
import RightSection from "./RightSection";
import { Context } from "../../AppContext";
import { navigate } from "gatsby";
import Preview from "rev.sdk.js/Components/RichTextPreview";
const queryString = require("query-string");

const DOCUMENT_NAME = "Article_Default";

function ArticleDetailPage(props) {
  const parsed = queryString.parse(props.location.search);
  const { rwd } = useDimension();
  const labels = ["全部文章", ...props.pageContext.labels];
  const [article, setArticle] = React.useState(null);
  const [records, setRecords] = React.useState([]); // need to fetch records because the list view on the right
  const api = useRef(new ApiBlog()).current;
  const app = useContext(Context);

  const articleId = (loc => {
    const q = queryString.parse(loc.search);
    const subPaths = loc.href.replace(/\/$/, "").split("/");

    if (q && q.id) {
      return q.id;
    } else if (subPaths[subPaths.length - 1] !== "article") {
      let _p = subPaths[subPaths.length - 1];
      _p = _p.split("?")[0]; // remove all the query string
      return _p;
    }

    return 0;
  })(props.location);

  const getRecord = useCallback(
    async id => {
      try {
        let resp = await api.getBlog({
          documentName: DOCUMENT_NAME,
          id,
        });
        setArticle(resp);
      } catch (err) {
        console.warn(err);
      }
    },
    [api]
  );

  const getRecords = useCallback(async () => {
    try {
      const filters = [
        {
          label: { $not: { $regex: "FAQ" } },
        },
        {
          label: { $not: { $regex: "draft" } },
        },
      ];
      if (props.pageContext.label) {
        filters.push({
          label: { $regex: props.pageContext.label },
        });
      }

      let resp = await api.getBlogs({
        documentName: DOCUMENT_NAME,
        $and: filters,
      });
      setRecords(resp);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  // initial
  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      setArticle(null);
      await Promise.all([getRecord(articleId), getRecords()]);
      app.actions.setLoading(false);
    })();
  }, [parsed.id]);

  return (
    <Wrapper rwd={rwd}>
      <div className="center-content">
        <div className="content">
          <div className="left">
            {/* {article && <BlogDisplay blog={article} />} */}
            <Breadcrumb
              routes={[
                { label: "感官觀點", link: "/articles" },
                ...(article
                  ? [
                      {
                        label: article.label.slice(0, 1),
                        link: `/articles/${article.label.slice(0, 1)}`,
                      },
                    ]
                  : []),
              ]}
              seperator=">"
              style={{ marginBottom: 20 }}
            />
            {article && (
              <>
                <img
                  src={article.image}
                  width="100%"
                  height="auto"
                  alt="banner"
                  style={{ marginBottom: 30 }}
                />
                <h2 style={{ marginBottom: 50, fontSize: 26 }}>
                  {article.title}
                </h2>
                <div style={{ marginBottom: 15 }}>
                  {article.label.slice(0, 1)}
                  <span style={{ margin: "0 10px" }}>|</span>
                  {time.format(article.created)}
                </div>
                <div
                  style={{
                    height: 1,
                    width: 250,
                    backgroundColor: "#C8C8C8",
                    marginBottom: 15,
                  }}
                />
                <ShareLinks
                  id={parsed.id}
                  instance={article}
                  style={{ marginBottom: 50 }}
                />
                <Preview content={article.content} readOnly />
              </>
            )}

            <div style={{ marginBottom: 40 }} />
            <NextArticles
              current={article}
              articles={records}
              style={{ marginBottom: 40 }}
            />
          </div>

          <div className="sep" />

          <div className="right">
            <RightSection
              labels={labels}
              onSearch={value => {
                navigate(`/articles?keyword=${value}`);
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .center-content {
    max-width: 1240px;
    margin: 0px auto;
    /* padding: ${props => (props.rwd === "desktop" ? "0px" : "0px 20px")}; */

    & > .banner {
      padding-top: 66%;
      background-color: #ccc;
      position: relative;
    }

    & > .content {
      display: flex;
      padding: ${props => (props.rwd === "desktop" ? "0px" : "0px 20px")};
      flex-direction: ${props => (props.rwd === "desktop" ? "row" : "column")};
      padding-top: ${props => {
        if (props.rwd === "desktop") {
          return "70px";
        } else if (props.rwd === "pad") {
          return "40px";
        } else {
          return "20px";
        }
      }};
      & > .left {
        padding: ${props => (props.rwd === "desktop" ? "0px 20px 0 0" : "0px")};
        flex: 1;
        min-height: 500px;
        & > h1 {
          font-size: ${props => (props.rwd === "mobile" ? "24px" : "30px")};
          padding-bottom: ${props =>
            props.rwd === "mobile" ? "15px" : "10px"};
          border-bottom: 1px solid ${Theme.colors.unSelected};
          margin-bottom: 40px;
        }
      }

      & > .sep {
        flex-basis: 50px;
        flex-shrink: 0;
      }

      & > .right {
        flex-basis: 300px;
        flex-shrink: 0;
        
      }
    }
  }
`;

export default ArticleDetailPage;
