import React from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "@styled-icons/evil";
import moment from "moment";
import { Link } from "gatsby";
import useDimension from "../../hooks/use-dimension";

const theme = {
  gap: 25,
  color: "#aaa",
};

export default function NextArticles(props) {
  const { current, articles, style = {} } = props;
  const { rwd } = useDimension();
  let currentLabel =
    current && Array.isArray(current.label) && current.label.length > 0
      ? current.label[0]
      : "";

  let records = articles
    .filter(a => (a.label || "").indexOf(currentLabel) >= 0)
    .sort((a, b) => a.created - b.created);

  if (!current || records.length <= 2) {
    return null;
  }

  let idx = records.findIndex(x => x.id === current.id);
  let last = idx - 1 < 0 ? null : records[idx - 1];
  let next = idx + 1 >= records.length ? null : records[idx + 1];

  return (
    <Wrapper style={style} rwd={rwd}>
      {last && (
        <Item>
          <Link
            to={`/article?id=${last.id}`}
            className="icon"
            style={{ marginRight: theme.gap }}
          >
            <ChevronLeft color={theme.color} size={30} />
          </Link>
          <div className="content">
            <div className="title">{last.title}</div>
            <div className="subtitle">{`${last.label} | ${moment(
              last.created * 1000
            )
              .format("YYYY.MM.DD.ddd")
              .toUpperCase()}`}</div>
          </div>
        </Item>
      )}

      {last && next && <div className="sep" />}

      {next && (
        <Item>
          <div className="content">
            <div className="title">{next.title}</div>
            <div className="subtitle">{`${next.label} | ${moment(
              next.created * 1000
            )
              .format("YYYY.MM.DD.ddd")
              .toUpperCase()}`}</div>
          </div>
          <Link
            to={`/article?id=${next.id}`}
            className="icon"
            style={{ marginLeft: theme.gap }}
          >
            <ChevronRight color={theme.color} size={30} />
          </Link>
        </Item>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  flex-direction: ${props => (props.rwd === "mobile" ? "column" : "row")};

  & > .sep {
    flex: 0 0 1px;
    align-self: stretch;
    background-color: ${theme.color};
    margin: ${props => (props.rwd === "mobile" ? "0" : theme.gap + "px 0px")};
  }
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: ${theme.gap}px;

  & > .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px ${theme.color};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.5;
    }
  }

  & > .content {
    align-self: stretch;
    display: flex;
    flex-direction: column;

    & > .title {
      font-size: 16px;
      font-weight: 500;
      color: #707070;
      margin-bottom: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      flex: 1;
    }

    & > .subtitle {
      color: ${theme.color};
    }
  }
`;
