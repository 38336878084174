import React, { useContext } from "react";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import { Facebook, Twitter } from "@styled-icons/boxicons-logos";
import { Line } from "@styled-icons/fa-brands";
import { Link } from "@styled-icons/boxicons-regular";
import { message } from "antd";
import * as Theme from "../../Theme";
import Constants from "../../constants";
import styled from "styled-components";
import { Context } from "../../AppContext";

export default function ShareLinks({ id, style, instance }) {
  const app = useContext(Context);
  const { title } = instance;
  const [reurl, setReurl] = React.useState(
    `${Constants.webUrl}/article?id=${id}`
  );

  const defaultProps = {
    // disabled: !id,
    disabled: false,
    url: reurl,
    resetButtonStyle: false,
    className: "link",
  };
  const IconProps = {
    size: 20,
    color: "white",
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(reurl);
    message.success("複製分享連結成功。");
  };

  React.useEffect(() => {
    const { origin, pathname } = window.location;
    setReurl(origin + pathname);
  }, []);

  return (
    <Wrapper
      style={{
        display: "flex",
        alignItems: "center",
        ...style,
      }}
    >
      <FacebookShareButton quote={title} {...defaultProps}>
        <Facebook {...IconProps} />
      </FacebookShareButton>
      <LineShareButton title={title} {...defaultProps}>
        <Line {...IconProps} />
      </LineShareButton>
      <TwitterShareButton title={title} {...defaultProps}>
        <Twitter {...IconProps} />
      </TwitterShareButton>
      <div className="link" onClick={() => copyShareLink()}>
        <Link {...IconProps} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  > .link {
    margin-right: 10px;
    outline: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b4b4b5;

    &:hover {
      background-color: ${Theme.colors.brown};
    }
  }
`;
